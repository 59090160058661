import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { size } from '../../../styles/theme'

export const VideoDescription = styled.p`
  ${tw`text-white__deeper font-medium text-center text-sm mt-5 mb-0`}
`

export const PlayerWrapper = styled.div<{
  isShowTwoVideo?: boolean
  playing?: boolean
  isHomePage?: boolean
  isVideoBackground?: boolean
  bgImage?: string
}>`
  ${tw`relative bg-blue__deep overflow-hidden flex flex-col mb-1 justify-center items-center w-full lg:mb-0`}
  height: 0;
  padding-top: 56.25%;

  ${props =>
    props.bgImage &&
    css`
      background: url(${props.bgImage}) top center no-repeat;
      background-size: cover;
    `}

  ${props =>
    props.isHomePage &&
    css`
      height: unset;
      min-height: 40rem;
      padding-top: 0;

      @media (min-width: 1024px) {
        min-height: ${size(350)};
      }
    `}

  ${props =>
    props.isVideoBackground &&
    css`
      ${tw`absolute w-full h-full`}
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
      

  &:after {
    ${tw`absolute w-full h-full`};
    top: 0;
    left: 0;
    content: '';
    background: rgba(0, 0, 0, 0.3);
  }

  ${props =>
    props.playing &&
    css`
      &:after {
        display: none;
      }
    `}

  ${VideoDescription} {
    ${tw`text-lg text-white`}
  }

  ${props =>
    props.isShowTwoVideo &&
    css`
      height: ${size(268)};
      min-height: unset;

      @media (min-width: 1024px) {
        min-height: unset;
      }

      @media (max-width: 768px) {
        height: ${size(226)};
        margin: 0;
      }
    `}

  .icon-plus {
    ${tw`absolute z-10`}
    bottom: -4px;
    right: 0;
    top: unset;
    margin-right: 0;

    svg {
      width: 118px;
      height: 114px;
    }
  }
`

export const PlayButtonWrap = styled.a`
  ${tw`m-0 text-center absolute z-10 rounded-full cursor-pointer hover:bg-black__deep transition duration-300`}
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 70px !important;
    height: 70px;
  }
`

export const ReactPlayerWrapper = styled.div<{ active?: boolean }>`
  ${tw`absolute top-0 w-full h-full hidden`}

  ${props =>
    props.active &&
    css`
      ${tw`block`}
    `}

  .react-player {
    ${tw`absolute top-0 left-0 w-full h-full`}
    width: 100% !important;
  }
`

export const VideoThumb = styled.div<{ thumbURL?: string }>`
  ${tw`absolute top-0 left-0 w-full h-full`};
  transform: scale(1.02);

  ${props =>
    props.thumbURL &&
    css`
      background: url(${props.thumbURL}) center/cover no-repeat;
    `}
`

export const VideoBlock = styled.div`
  ${tw`flex w-full justify-end flex-col mb-10 md:mb-16`}
`
