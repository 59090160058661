import React, { useEffect } from 'react'
import * as S from './PopupOwnerAndPT.styled'
import * as G from '../../../styles/global.styles'
import { IconEnums } from '../../base'
import { YOU_FITNESS } from '../../../services/api/constants'
import { PersonalTrainer } from '../../personal-trainers/PersonalTrainers'

interface IPopupOwnerAndPT {
  closePopup: () => void
  popupData: PersonalTrainer
}

function PopupOwnerAndPT({ closePopup, popupData }: IPopupOwnerAndPT) {
  useEffect(() => {
    document.body.style.overflow = !!popupData ? 'hidden' : ''
  }, [popupData])

  const onClosePopup = () => {
    document.body.style.overflow = ''
    closePopup && closePopup()
  }

  return (
    <S.PopupWrapperNew className='bg-black bg-opacity-75'>
      <S.PopupContainerNewLayout>
        <S.PopupHeader onClick={onClosePopup}>
          <G.IconNew name={IconEnums['close']} />
        </S.PopupHeader>
        <S.PopupBody>
          <S.GymCommonTitle>{popupData.fullname}</S.GymCommonTitle>
          <S.PopupCompContact>
            {popupData.phone && (
              <S.PopupLink href={`tel:${popupData.phone}`}>
                <span>P: </span>
                <div>{popupData.phone}</div>
              </S.PopupLink>
            )}
          </S.PopupCompContact>
          <S.PopupMainBody>
            <S.PopupAvatarNew>
              <G.DefaultImg
                objectFit={'cover'}
                src={popupData.avatar}
                alt={popupData.fullname}
              />
            </S.PopupAvatarNew>
            <S.PopupDescNew>
              <S.PopupInfoContent
                content={popupData.description ? popupData.description : ''}
              >
                <S.TextDescriptionNew
                  dangerouslySetInnerHTML={{
                    __html: popupData.description ? popupData.description : ''
                  }}
                />
              </S.PopupInfoContent>
            </S.PopupDescNew>
          </S.PopupMainBody>
          <S.PopupFooter>
            {popupData.phone && (
              <G.WhiteButton
                className={'call-now'}
                href={`tel:${popupData.phone}`}
              >
                <S.btnIcon>
                  <G.Icon name={IconEnums['icon-phone']} />
                </S.btnIcon>
                {YOU_FITNESS.callNowBtn}
              </G.WhiteButton>
            )}
            {popupData.email && (
              <G.WhiteButton href={`mailto:${popupData.email}`}>
                <S.btnIcon>
                  <G.Icon name={IconEnums['icon-email']} />
                </S.btnIcon>
                {YOU_FITNESS.emailBtn}
              </G.WhiteButton>
            )}
            {popupData.instagram_url && (
              <G.WhiteButton href={popupData.instagram_url}>
                <S.btnIcon>
                  <G.Icon name={IconEnums['icon-instagram3']} />
                </S.btnIcon>
                {YOU_FITNESS.instagramBtn}
              </G.WhiteButton>
            )}
          </S.PopupFooter>
        </S.PopupBody>
      </S.PopupContainerNewLayout>
    </S.PopupWrapperNew>
  )
}

export default PopupOwnerAndPT
