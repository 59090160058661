import { useEffect, useState, useContext } from 'react'
import { EnquiryForm, EnquiryFormRow } from './MemberEnquiryForm.styled'
import * as F from './Form.styled'
import * as G from '../../styles/global.styles'
import { Button } from '../base/Button'
import { FormsProps, SelectOption } from '../../services/api/types'
import { getChoices, deboundRequest } from '../../services/utils'
import {
  SVGIcon,
  IconEnums,
  TextInput,
  DropdownStyled,
  TextAreaInput,
  Radio,
  CheckBox,
  BlockTitle,
  GymsSelect,
  FileInput,
  DatePickerStyled
} from '../base'
import useGymOptions from '../../hooks/useGymOptions'
import { RECAPTCHA_KEY } from '../../services/api/constants'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import PrivacyField from './PrivacyField'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { SiteContext } from '../../services/context/SiteContext'
import { getSiteCode } from '../../services/api/helpers'

export interface StandardFormProps extends FormsProps {
  formik: any
  onSubmit?: Function
  onClose?: Function
}

export const StandardForm = (props: StandardFormProps) => {
  const {
    fields,
    title,
    formik,
    preamble,
    submitText,
    formAction,
    onClose
  } = props
  const {
    gymOptions,
    isLoadingGyms,
    setChoosenState,
    setChoosenPostCode
  } = useGymOptions('', '', fields, props.slug)

  const hasRecaptcha =
    fields &&
    fields.length > 0 &&
    fields?.find(it => it.field_block === 'recaptcha')
      ? true
      : false

  const [recaptchaDisableSubmit, setRecaptchaDisableSubmit] = useState(
    hasRecaptcha
  )

  const { siteInfor } = useContext(SiteContext)
  const siteCode = getSiteCode(siteInfor?.root_url)

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED && hasRecaptcha) {
      loadReCaptcha(RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [hasRecaptcha])

  return (
    <G.Section className='standard-form' spacing={12}>
      <F.FormWrapper>
        {onClose && (
          <F.CloseWrapper onClick={() => onClose()}>
            <SVGIcon name={IconEnums.close} />
          </F.CloseWrapper>
        )}
        {title && <BlockTitle textTransform='uppercase'>{title}</BlockTitle>}
        {preamble && <F.PreFormText>{preamble}</F.PreFormText>}
        <EnquiryForm action={formAction} onSubmit={formik.handleSubmit}>
          {fields?.map((field, idx) => {
            let inputComp
            const isInvalid =
              formik && formik.errors[field.name] && formik.touched[field.name]
            switch (field.type) {
              case 'text_area': {
                switch (field.field_block) {
                  case 'label':
                    return (
                      <F.FormGroup col={1} key={idx}>
                        <F.FormLabel>{field.label}</F.FormLabel>
                      </F.FormGroup>
                    )
                  case 'heading':
                    return (
                      <BlockTitle
                        className='width-full'
                        textTransform='uppercase'
                      >
                        {field.label}
                      </BlockTitle>
                    )
                  case 'multiline':
                    inputComp = (
                      <TextAreaInput
                        rows={4}
                        cols={28}
                        name={field.name}
                        borderColor={isInvalid ? 'red' : undefined}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )
                    break
                }
                break
              }
              case 'select': {
                const selectOptions = getChoices(field.choices)
                if (field.field_block == 'gym') {
                  inputComp = (
                    <GymsSelect
                      placeholder={field.help_text}
                      name={field.name}
                      defaultOptions={gymOptions}
                      onChange={(option: SelectOption) =>
                        formik.setFieldValue(field.name, option.value)
                      }
                      onBlur={formik.handleBlur}
                      isDisabled={isLoadingGyms}
                      isLoading={isLoadingGyms}
                      formSlug={props.slug}
                    />
                  )
                } else {
                  inputComp = (
                    <DropdownStyled
                      placeholder={field.help_text}
                      name={field.name}
                      options={selectOptions}
                      onChange={(option: SelectOption) => {
                        if (field.name === 'state') {
                          setChoosenState(option.value)
                        }
                        formik.setFieldValue(field.name, option.value)
                      }}
                      onBlur={formik.handleBlur}
                    />
                  )
                }
                break
              }
              case 'checkbox': {
                const selectOptions = getChoices(field.choices)
                if (field.field_block === 'multi_select') {
                  inputComp = (
                    <DropdownStyled
                      placeholder={field.help_text}
                      name={field.name}
                      options={selectOptions}
                      isMulti
                      onChange={(options: SelectOption[]) => {
                        formik.setFieldValue(
                          field.name,
                          options?.map(it => it.value) ?? ''
                        )
                      }}
                      onBlur={formik.handleBlur}
                    />
                  )
                } else {
                  inputComp = (
                    <>
                      {selectOptions.map(op => {
                        return (
                          <CheckBox
                            name={field.name}
                            type={field.type}
                            value={op.value}
                            label={op.label}
                            color='black'
                            borderColor={isInvalid ? 'red' : 'white'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        )
                      })}
                    </>
                  )
                }
                break
              }
              case 'radio': {
                const selectOptions = getChoices(field.choices)
                inputComp = (
                  <>
                    {selectOptions.map(op => {
                      return (
                        <Radio
                          name={field.name}
                          type={field.type}
                          value={op.value}
                          label={op.label}
                          color='black'
                          borderColor={isInvalid ? 'red' : 'white'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      )
                    })}
                  </>
                )
                break
              }
              case 'file': {
                inputComp = (
                  <FileInput
                    name={field.name}
                    type={field.type}
                    text={field.label}
                    color='black'
                    borderColor={isInvalid ? 'red' : 'white'}
                    onChange={(event: any) =>
                      formik?.setFieldValue(
                        field.name,
                        event.currentTarget.files[0]
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                )
                break
              }
              case 'text': {
                if (field.field_block == 'date') {
                  inputComp = (
                    <DatePickerStyled
                      name={field.name}
                      setFieldValue={formik?.setFieldValue}
                      showYearDropdown
                    />
                  )
                } else if (field.field_block == 'datetime') {
                  inputComp = (
                    <DatePickerStyled
                      timePicker={true}
                      name={field.name}
                      setFieldValue={formik?.setFieldValue}
                      showYearDropdown
                    />
                  )
                } else if (field.field_block == 'phone_number') {
                  inputComp = (
                    <PhoneInput
                      country={siteCode}
                      enableSearch
                      countryCodeEditable={false}
                      placeholder={field.help_text}
                      value={formik.values[field.name]}
                      onChange={(value: any, data: CountryData) => {
                        formik.setFieldValue(field.name, `+${value}`)
                        formik.setFieldValue(
                          'country-code',
                          `+${data.dialCode}`
                        )
                      }}
                      onBlur={formik.handleBlur}
                    />
                  )
                } else {
                  inputComp = (
                    <TextInput
                      placeholder={field.help_text}
                      name={field.name}
                      type={field.type}
                      color='black'
                      borderColor={isInvalid ? 'red' : 'white'}
                      onChange={(e: any) => {
                        if (field.name === 'postcode') {
                          deboundRequest(setChoosenPostCode, e.target.value)
                        }
                        formik.setFieldValue(field.name, e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                    />
                  )
                }
                break
              }
              case 'input': {
                if (field.field_block == 'recaptcha') {
                  inputComp = (
                    <ReCaptcha
                      sitekey={RECAPTCHA_KEY}
                      action='ownEnquire'
                      verifyCallback={async (token: any) => {
                        if (formik.values['recaptcha'] !== token)
                          await formik.setFieldValue('recaptcha', token, false)
                        setRecaptchaDisableSubmit(false)
                      }}
                    />
                  )
                } else {
                  inputComp = (
                    <TextInput
                      placeholder={field.help_text}
                      name={field.name}
                      type={field.type}
                      color='black'
                      borderColor={isInvalid ? 'red' : 'white'}
                      onChange={(e: any) => {
                        if (field.name === 'postcode') {
                          deboundRequest(setChoosenPostCode, e.target.value)
                        }
                        formik.setFieldValue(field.name, e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                    />
                  )
                }
                break
              }
              case 'hidden': {
                return ''
              }
              default:
                inputComp = (
                  <TextInput
                    placeholder={field.help_text}
                    name={field.name}
                    type={field.type}
                    color='black'
                    borderColor={isInvalid ? 'red' : 'white'}
                    onChange={(e: any) => {
                      if (field.name === 'postcode') {
                        deboundRequest(setChoosenPostCode, e.target.value)
                      }
                      formik.setFieldValue(field.name, e.target.value)
                    }}
                    onBlur={formik.handleBlur}
                  />
                )
                break
            }
            return field.field_block !== 'recaptcha' &&
              field.field_block !== 'privacypolicy' ? (
              <F.FormGroup col={1} key={idx}>
                <F.FormLabel>{field.label}</F.FormLabel>
                {inputComp}
                <F.ErrorMessage>
                  {formik.touched[field.name] && formik.errors[field.name]}
                </F.ErrorMessage>
              </F.FormGroup>
            ) : field.field_block === 'privacypolicy' ? (
              <F.FormGroup col={12} paddingX={6} key={idx}>
                <PrivacyField field={field} formik={formik} />
                <F.ErrorMessage>
                  {formik?.touched[field.name] && formik?.errors[field.name]}
                </F.ErrorMessage>
              </F.FormGroup>
            ) : (
              <div key={idx}>
                {inputComp}
                <F.ErrorMessage>
                  {formik?.touched[field.name] && formik?.errors[field.name]}
                </F.ErrorMessage>
              </div>
            )
          })}
          {formik.isSubmitting ? (
            <G.SpinnerWrap>
              <G.Spinner />
            </G.SpinnerWrap>
          ) : (
            <EnquiryFormRow justify='center'>
              <Button
                type='submit'
                disabled={formik.isSubmitting || recaptchaDisableSubmit}
                color={'blue'}
              >
                {submitText || 'Submit'}
              </Button>
            </EnquiryFormRow>
          )}
        </EnquiryForm>
      </F.FormWrapper>
    </G.Section>
  )
}

export default StandardForm
