import Head from 'next/head'
import { WagtailBlockProps } from '../../services/api/types'
import { VideoPlayer } from '../blocks/YouFitnessTemplate/VideoBlock'
import * as S from './HeroVideo.styled'

export interface HeroVideoProps extends WagtailBlockProps {
  value: {
    top_left_text: string
    top_right_text: string
    video_url: string
    center_text: string
    image_url: string
    isHomePage?: boolean
  }
}

const HeroVideo = (props: HeroVideoProps) => {
  const {
    top_left_text,
    top_right_text,
    video_url,
    image_url,
    center_text,
    isHomePage
  } = props.value

  return (
    <>
      {image_url && (
        <Head>
          <link rel='preload' href={image_url} as='image' type='image/jpeg' />
        </Head>
      )}
      <S.SectionHero isHomePage={isHomePage}>
        <S.Hero isHomePage={isHomePage} isVideo={video_url ? true : false}>
          {video_url ? (
            <>
              <VideoPlayer
                videoURL={video_url}
                thumbnailURL={image_url ?? undefined}
                autoPlay={true}
                loop
                isVideoBackground
                isHomePage={isHomePage}
              />
              <S.Plus src='/static/svgs/icon-plus.svg' alt='' />
            </>
          ) : (
            <S.Image src={image_url} alt={center_text} />
          )}
          <S.FeaturedText dangerouslySetInnerHTML={{ __html: center_text }} />
          <S.Text position='left'>{top_left_text}</S.Text>
          <S.Text position='right'>{top_right_text}</S.Text>
        </S.Hero>
      </S.SectionHero>
    </>
  )
}
export default HeroVideo
