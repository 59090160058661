import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { ButtonLink } from '../base'

export const BlocksWrapper = styled.div`
  ${tw`w-full flex flex-wrap items-stretch justify-center mx-auto`}

  @media (max-width: 767px) {
    ${tw`block`}
  }
`
export const Block = styled.div<{
  even?: boolean
  isFull?: boolean
}>`
  ${tw`flex flex-col items-start md:flex-row gap-4 uppercase w-full md:items-center text-white mb-10 pt-5 mx-8`}

  ${({ even }) =>
    even &&
    css`
      ${Number} {
        ${tw`text-blue__deep`}
      }
    `}

  > div:last-child {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    ${tw`w-full flex mx-0 px-4`}

    ${({ even }) =>
      even &&
      css`
        ${tw`justify-end`}
      `}

      > div:last-child {
      margin-left: unset;
    }
  }
`

export const BlockInner = styled.div`
  flex: 0 0 225px;
  ${tw`flex items-center font-bold`}

  @media (max-width: 767px) {
    flex: 1;
  }
`

export const BlockDescription = styled.div`
  ${tw`normal-case`}
`

export const Number = styled.div`
  font-size: 144px;
  line-height: 0.55;
  width: 90px;
  ${tw`flex-initial font-youfitnessHeading font-bold flex items-center`}
`

export const TextWrapper = styled.div`
  ${tw`flex-1 flex items-center pl-2`}
`

export const Text = styled.div`
  width: 120px;
  ${tw`text-base`}

  @media (max-width: 767px) {
    width: 150px;
  }
`

export const Button = styled(ButtonLink)`
  ${tw`ml-auto whitespace-no-wrap py-2`}
`
