import { useState } from 'react'
import * as S from './GymOwnerBlock.styled'
import ModalSlider from '../../franchise-page/ModalSlider'

import { PersonBlock } from './PersonBlock'
import { Colors, GymOwnerBlockAPIProps } from '../../../services/api/types'
import { Container, Section, Col, Row } from '../../../styles/grid.styles'
import HeaderWithTitle from '../../header/YouFitnessTemplate/HeaderWithTitle'
import { PersonalTrainer } from '../../personal-trainers/PersonalTrainers'
import PopupOwnerAndPT from './PopupOwnerAndPT'

export interface VideoLink {
  image: string
  video: string
}
export interface GymOwnerBlockProps {
  owners?: PersonalTrainer[]
  headline?: string
  subtext?: string
  background_colour?: Colors
  videos: VideoLink[]
}

export const GymOwnerBlock = (props: GymOwnerBlockProps) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [popupData, setPopupData] = useState<PersonalTrainer | null>()
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const { owners, headline, subtext, videos } = props

  const openPopup = (trainer: PersonalTrainer) => {
    setPopupData(trainer)
  }
  const toggleModal = () => {
    setOpen(!isOpen)
  }
  const closePopup = () => {
    setPopupData(null)
  }
  const getColumnSizes = (count: number, idx: number) => {
    if (count === 1) {
      return { md: 12, lg: 12 }
    } else if (count === 2) {
      return { md: 6, lg: 6 }
    } else if (count === 3) {
      if (idx === 1) {
        return { md: 6, lg: 6 }
      } else {
        return { md: 3, lg: 3 }
      }
    } else {
      return { md: 4, lg: 3 }
    }
  }
  return (
    <>
      <Section>
        {popupData && (
          <PopupOwnerAndPT closePopup={closePopup} popupData={popupData} />
        )}
        <Container size='lg'>
          {headline && subtext ? (
            <HeaderWithTitle headerText={headline} featuredText={subtext} />
          ) : headline || subtext ? (
            <S.BlockTitle center>
              {headline}
              {subtext}
            </S.BlockTitle>
          ) : null}
          <Row gutter={30} tabletGutter={15}>
            {owners &&
              owners.map((own, idx) => {
                let videoIndex = videos.findIndex(
                  video => video.video === own.video
                )
                let columnSizes = getColumnSizes(owners.length, idx)
                return (
                  <Col md={columnSizes.md} lg={columnSizes.lg} key={idx}>
                    <PersonBlock
                      {...own}
                      viewMore={() => openPopup(own)}
                      toggleModal={() => toggleModal()}
                      setSelectedIndex={() => setSelectedIndex(videoIndex)}
                      idx={idx}
                    />
                  </Col>
                )
              })}
          </Row>
        </Container>
      </Section>

      <ModalSlider
        isOpen={isOpen}
        toggleModal={toggleModal}
        initIndex={selectedIndex}
        media={videos}
      />
    </>
  )
}

GymOwnerBlock.ApiBlockToProps = (
  data: GymOwnerBlockAPIProps
): GymOwnerBlockProps => {
  const apiData = data.value
  const { gym_owners } = apiData
  let videoData: Array<VideoLink> = []
  const ownerData = gym_owners?.map(owner => {
    const owerInfo = owner.text?.split('.')
    const owerExert = owerInfo.shift()
    {
      owner.video &&
        videoData.push({
          video: owner.video,
          image: owner.image || ''
        })
    }
    return {
      avatar: owner.image,
      fullname: owner.name,
      bios: owner.bold_text,
      video: owner.video,
      description: owner.text,
      excert: `${owerExert}.`
    }
  })

  return {
    ...apiData,
    owners: ownerData,
    headline: apiData.headline,
    videos: videoData,
    background_colour: apiData.background_colour.background_colour
  }
}

export default GymOwnerBlock
