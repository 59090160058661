import { Colors, WagtailBlockProps } from '../../services/api/types'
import { Section, Container } from '../../styles/grid.styles'
import { BlockTitle } from '../base'
import RegisterYourInterestModal from './RegisterYourInterestModal'
import { useContext } from 'react'
import { SiteContext } from '../../services/context/SiteContext'

import * as G from '../../styles/global.styles'
import * as S from './VerticalListBlock.styled'

interface Item {
  text: string
  description: string
}

export interface VerticalListBlockProps {
  title: string
  subTitle: string
  listItems: Item[]
  background_colour?: Colors
}

export interface VerticalListBlockAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    text: string
    list_items: Item[]
    background_colour: {
      background_colour?: Colors
    }
  }
}

export const VerticalListBlock = (props: VerticalListBlockProps) => {
  const { listItems, title, subTitle } = props
  const { formBrochure } = useContext(SiteContext)

  return (
    <Section>
      <Container>
        {title && (
          <BlockTitle
            textColor='white'
            textTransform='uppercase'
            isYouFitness
            underlineColor='blue-deep'
          >
            {title}
          </BlockTitle>
        )}
        {subTitle && (
          <G.Text
            color='white'
            style={{ textAlign: 'center', marginBottom: 20 }}
          >
            {subTitle}
          </G.Text>
        )}
        <S.BlocksWrapper>
          {listItems?.length > 0 &&
            listItems.map((block, i) => (
              <S.Block key={i} even={(i + 1) % 2 === 0}>
                <S.BlockInner>
                  <S.Number>{i + 1}</S.Number>
                  <S.TextWrapper>
                    <S.Text>{block.text}</S.Text>
                  </S.TextWrapper>
                </S.BlockInner>
                <S.BlockDescription>{block.description}</S.BlockDescription>
                <div>
                  <RegisterYourInterestModal
                    button={
                      <S.Button color='blue-deep'>Find out more</S.Button>
                    }
                    downloadFileOnSuccess={true}
                    form={formBrochure}
                    fileUrl={formBrochure?.linkUrl ? formBrochure.linkUrl : ''}
                  />
                </div>
              </S.Block>
            ))}
        </S.BlocksWrapper>
      </Container>
    </Section>
  )
}

VerticalListBlock.ApiBlockToProps = (data: VerticalListBlockAPIProps) => {
  const { value } = data
  if (data.value) {
    let props: VerticalListBlockProps = {
      title: value.headline,
      subTitle: value.text,
      listItems: value.list_items,
      background_colour: value.background_colour.background_colour
    }
    return props
  }
}

export default VerticalListBlock
