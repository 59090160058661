import tw from 'twin.macro'
import styled from 'styled-components'
import { SVGIcon } from '../../base/icon/svgIcon'

export const Socials = styled.div`
  ${tw`flex justify-center items-center w-full mt-8 md:mt-10`}
`

export const ButtonWrap = styled.div`
  ${tw`text-center pt-8 md:pt-10`}
`

export const SocialsLink = styled.a`
  width: 44px;
  height: 44px;
  ${tw`bg-blue__deep text-white hover:bg-blue__dark2 rounded-full mx-2.5 flex items-center justify-center transition duration-300`}
`

export const Icon = styled(SVGIcon)`
  svg {
    max-width: 17px;
  }
`
